.tab {
  transition-duration: 0.4s;
}

.tab:hover {
  background-color: #cdcdcd;
}

.selected {
  background-color: #1589ff;
}

.not-selected {
  background-color: #dcdcdc;
}
