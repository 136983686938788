input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
  border: none;
}

.status {
  padding: 10px 0 10px;
  margin-bottom: 20px;
  color: #c01324;
  font-weight: bold;
  opacity: 0;
  -webkit-transition: opacity 1000ms linear;
  transition: opacity 1000ms linear;
}
.status__heading {
  margin: 0;
}
.status:first-letter {
  text-transform: capitalize;
}

.form-controls {
  height: 34px;
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-size: 16px;
}
.form-controls:focus {
  outline: none;
  border-color: #00a9e0;
}

.disabled-bkg {
  background: #aaa;
}
#submit:disabled {
  cursor: not-allowed;
}
.payment-fields.disabled {
  cursor: not-allowed;
}
.firstdata-field-focused,
.focus {
  outline: none;
  border-color: #00a9e0;
}
.firstdata-field-invalid,
.invalid {
  color: #c01324;
  border-color: #c01324;
}
.firstdata-field-valid,
.valid {
  border-color: #43b02a;
}

label.focus,
label.valid,
label.invalid {
  border: none;
}

.success {
  color: #43b02a;
}
.success-bkg {
  background: #43b02a;
}
